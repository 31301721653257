<template>
  <div class="right-chart-2">
    <div class="lc2-details">最新状态</div>
    <dv-scroll-board :config="config" class="lc2-chart" />
  </div>
</template>

<script>
import * as assetsApi from '@/api/ams/assets'
export default {
  name: 'RightChart2',
  data() {
    return {
      config: {
        data: []
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      assetsApi
        .alertAssets()
        .then((res) => {
          if (res.code === 0) {
            this.config = {
              data: res.data
            }
          } else {
            this.$message.error(res.msg)
          }
          setTimeout(this.init, 60 * 1000)
        })
        .catch((e) => {
          setTimeout(this.init, 60 * 1000)
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less">
.right-chart-2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .lc2-header {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    text-indent: 20px;
    margin-top: 10px;
  }

  .lc2-details {
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 10px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .lc2-chart {
    height: calc(~'100% - 80px');
  }
}
</style>
